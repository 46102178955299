import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import config from '../../config';
import FileService from '../../services/FileService';
import ErrorPage from '../ErrorPage';
import ListView from '../list/ListView';
import { Invoice } from '../payment/payment.types';
import { useNavigate } from 'react-router-dom';
import {
  ColDef,
  ICellRendererParams,
  IRowNode,
  RowClassParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';

interface PayArgs {
  totalAmountStr: string;
  totalAmount: number;
  invoices: Invoice[];
}

interface Props {
  currencyCode?: string;
  canAcceptPayment?: boolean;
  invoices: any[];
  onPay?: (args: PayArgs) => void;
}

const getDaysPastDue = (date: string) => {
  return Math.abs(dayjs(date).diff(dayjs(), 'day'));
};

const defaultGridOptions = {
  getRowClass: (params: RowClassParams) => {
    const daysPastDue = getDaysPastDue(params.data.date);
    if (daysPastDue >= 90) {
      return 'imp-bg-danger';
    } else if (daysPastDue >= 60) {
      return 'imp-bg-warning';
    }
  },
};

const AccountBalanceList = ({ currencyCode = 'usd', canAcceptPayment = true, invoices, onPay }: Props) => {
  const [selectedInvoices, setSelectedInvoices] = useState<IRowNode[]>([]);
  const navigate = useNavigate();

  let columnDefs: ColDef[] = [];

  useEffect(() => {
    // when invoices change, reset selected invoices
    setSelectedInvoices([]);
  }, [invoices]);

  if (canAcceptPayment) {
    columnDefs.push({
      width: 50,
      checkboxSelection: true,
      suppressMenu: true,
      pinned: 'left',
      headerCheckboxSelection: true,
      sortable: false,
    });
  }

  const actionCol = {
    headerName: 'View/Download',
    field: 'action',
    // suppressSizeToFit: true,
    suppressMenu: true,
    sortable: false,
    cellClass: 'ag-col-center',
    width: 90,
    cellRenderer: (cellProps: any) => {
      const invoiceNum = cellProps.data.invoice;

      //   if (company.num === 329 && invoiceNum.includes('FCHG')) {
      //     return null;
      //   }

      const fileUrl = `${config.apiUrl}/invoices/${invoiceNum}?type=invoice`;

      const onView = () => {
        window.open(fileUrl);
      };

      const onDownload = () => {
        FileService.downloadDataUri(`${fileUrl}&download=1`, invoiceNum);
      };

      return (
        <>
          <ButtonGroup className="list-row-toolbar">
            <Button className="btn-xs" onClick={onView} title="View">
              <i className="fas fa-list" />
            </Button>
            <Button className="btn-xs" onClick={onDownload} title="Download">
              <i className="fas fa-download" />
            </Button>
          </ButtonGroup>
        </>
      );
    },
  };

  columnDefs = columnDefs.concat([
    actionCol,
    {
      headerName: 'Invoice #',
      field: 'invoice',
      cellRenderer: (params: ICellRendererParams) => {
        // if (company.num === 329 && params.value.includes('FCHG')) {
        //   return 'Finance Charge';
        // }

        return params.value;
      },
    },
    {
      headerName: 'Date',
      field: 'date',
      sort: 'asc',
      cellRenderer: ({ value, data }: ICellRendererParams) => {
        const daysPastDue = getDaysPastDue(value);
        if (data.amount <= 0) {
          return value;
        }

        if (daysPastDue >= 90) {
          return <span className="text-bold">{value} (90+ days past due)</span>;
        } else if (daysPastDue >= 60) {
          return <span className="text-bold">{value} (60+ days past due)</span>;
        } else if (daysPastDue >= 30) {
          return <span className="text-bold">{value} (30+ days past due)</span>;
        }

        return value;
      },
    },
    {
      headerName: 'Amount',
      field: 'amount',
      valueFormatter: (params: ValueFormatterParams) => `$${params.value || '-'}`,
      cellStyle: { textAlign: 'right' },
    },
    {
      headerName: 'Note',
      field: 'note',
      cellRenderer: (params: ICellRendererParams) => {
        return <span className="text-success">{params.value}</span>;
      },
    },
  ]);

  const gridOptions = canAcceptPayment
    ? {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        isRowSelectable: (node: IRowNode) => {
          return node.data ? node.data.amount >= 0 && !node.data.note : false;
        },
        onSelectionChanged: (event: SelectionChangedEvent<any>) => {
          setSelectedInvoices(event.api.getSelectedNodes());
        },
        suppressRowClickSelection: true,
      }
    : {};

  const sumFloats = (x: number, y: number) => parseFloat((x + y).toFixed(2));

  const getTotalAmount = () => {
    let totalAmount = 0;
    for (const node of selectedInvoices) {
      const { amount } = node.data;
      totalAmount = sumFloats(Number(amount), totalAmount);
    }

    const totalAmountStr = totalAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return { totalAmount: Number(totalAmount.toFixed(2)), totalAmountStr };
  };

  const handlePay = () => {
    if (onPay) {
      const invoiceData = [];
      for (const node of selectedInvoices) {
        const { amount, invoice, date, glacct } = node.data;
        invoiceData.push({ amount, invoice, date, glacct });
      }

      onPay({ ...getTotalAmount(), invoices: invoiceData });
    }
  };

  const renderSelectedInvoices = () => {
    const { totalAmountStr } = getTotalAmount();

    if (!canAcceptPayment) {
      return;
    }

    return (
      <div style={{ marginLeft: '15px' }}>
        <Button variant="success" type="button" size="lg" onClick={handlePay} disabled={!selectedInvoices.length}>
          {!selectedInvoices.length && 'Select Invoices To Pay'}
          {!!selectedInvoices.length && (
            <>
              Pay ${totalAmountStr} &nbsp; <i className="far fa-arrow-right" />
            </>
          )}
        </Button>
        {/* <Button
          onClick={() => {
            navigate('/payment/add-card');
          }}
        >
          Add Payment Method
        </Button> */}
      </div>
    );
  };

  if (!invoices.length) {
    return (
      <ErrorPage>
        <div className="text-success">
          <i className="far fa-thumbs-up" /> No Balance
        </div>
      </ErrorPage>
    );
  }

  return (
    <>
      {renderSelectedInvoices()}
      <ListView
        canAdd={false}
        canDelete={false}
        canEdit={false}
        columnDefs={columnDefs}
        gridOptions={{ ...defaultGridOptions, ...gridOptions }}
        rowData={invoices}
        resizeOnUpdate={false}
      />
    </>
  );
};

export default AccountBalanceList;
