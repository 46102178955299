import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import dayjs from 'dayjs';
import Api from '../../services/ApiService';
import ListViewToolbar from '../list/ListViewToolbar';
import ListView from '../list/ListView';
import config from '../../config';
import FileService from '../../services/FileService';
import AsyncButton from '../form/AsyncButton';
import { useQuery } from '@tanstack/react-query';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { ICellRendererParams } from '@ag-grid-community/core';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const columnDefs = [
  {
    pinned: 'left',
    headerName: '',
    field: 'action',
    suppressSizeToFit: true,
    suppressMenu: true,
    sortable: false,
    cellClass: 'ag-col-center',
    width: 90,
    cellRenderer: (cellProps: any) => {
      const invoiceNum = cellProps.data.invnum;
      const docType = cellProps.data.type === 'Repair Order' ? 'ro' : 'invoice';
      const fileUrl = `${config.apiUrl}/invoices/${invoiceNum}?type=${docType}`;

      const onView = () => window.open(fileUrl);

      const onDownload = () => {
        FileService.downloadDataUri(`${fileUrl}&download=1`, invoiceNum);
      };

      return (
        <ButtonGroup className="list-row-toolbar">
          <AsyncButton timeout={5000} className="btn-xs" onClick={onView} title="View">
            <i className="fas fa-list" />
          </AsyncButton>
          <AsyncButton timeout={5000} className="btn-xs" onClick={onDownload} title="Download">
            <i className="fas fa-download" />
          </AsyncButton>
        </ButtonGroup>
      );
    },
  },
  {
    headerName: 'Date',
    field: 'date',
    cellRenderer: (data: ICellRendererParams) => {
      return dayjs(data.value).format('MMM D, YYYY - h:mm a');
    },
  },
  { headerName: 'PO #', field: 'ponum' },
  { headerName: 'Type', field: 'type' },
  {
    headerName: 'Amount',
    field: 'amount',
    valueFormatter: (params: any) => `$${params.value || '-'}`,
    cellStyle: { textAlign: 'right' },
  },
  { headerName: 'Invoice #', field: 'invnum' },
];

interface HistoryInvoice {
  time?: string;
  date: string;
}

const History = () => {
  const [endDate, setEndDate] = useState<Date | null>(dayjs().toDate());
  const [startDate, setStartDate] = useState<Date | null>(dayjs().subtract(1, 'month').toDate());

  const getHistory = async () => {
    const start = dayjs(startDate?.toString()).format('YYYY-MM-DD');
    const end = dayjs(endDate?.toString()).format('YYYY-MM-DD');

    const response = await Api.get('customers/history', { params: { startDate: start, endDate: end } });

    return response.data.invoice.map((inv: HistoryInvoice) => {
      if (inv.time) {
        inv.date += ` ${inv.time}`;
      }

      return inv;
    });
  };

  const {
    isLoading,
    error,
    data: invoices = [],
  } = useQuery({
    queryKey: ['history', startDate?.toString(), endDate?.toString()],
    enabled: !!startDate && !!endDate,
    queryFn: getHistory,
  });

  const handleStartDayChange = (d: Date) => {
    if (d) {
      const currentEndDate = dayjs(startDate);
      const newStartDate = dayjs(d);
      if (newStartDate.isAfter(currentEndDate)) {
        return;
      }
      setStartDate(d);
    }
  };

  const handleEndDayChange = (d: Date) => {
    if (d) {
      const currentStartDate = dayjs(startDate);
      const newEndDate = dayjs(d);
      if (newEndDate.isBefore(currentStartDate)) {
        return;
      }
      setEndDate(newEndDate.toDate());
    }
  };

  const handleStartDayBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleStartDayChange(dayjs(value).toDate());
  };

  const handleEndDayBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleEndDayChange(dayjs(value).toDate());
  };

  const renderRange = () => {
    return (
      <div className="filter-range">
        <div className="input-range">
          <DateRangePicker
            onChange={(dates: Value) => {
              if (!dates) {
                setStartDate(null);
                setEndDate(null);
              } else {
                if (!Array.isArray(dates)) {
                  setStartDate(dates);
                } else {
                  setStartDate(dates[0]);
                  setEndDate(dates[1]);
                }
              }
            }}
            value={[startDate, endDate]}
          />
          {isLoading && (
            <span className="through" style={{ display: 'inline-block' }}>
              <ScaleLoader height={12} />
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <ListView canAdd={false} canDelete={false} canEdit={false} columnDefs={columnDefs} rowData={invoices}>
      <ListViewToolbar canExport header="History" />
      {renderRange()}
    </ListView>
  );
};

export default History;
